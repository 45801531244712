/*
 *
 * Global configurations for Portal (FE)
 *
 */

let config = {}

config.PORTAL_URL = process.env.REACT_APP_PORTAL
config.STATIC_URL = process.env.REACT_APP_STATIC_URL
config.SSO_RESET_PASSWORD_URL = process.env.REACT_APP_SSO_RESET_PASSWORD_URL
config.SSO_LOGIN_IN_URL = process.env.REACT_APP_SSO_LOGIN_IN_URL
  ? process.env.REACT_APP_SSO_LOGIN_IN_URL
  : 'https://securesit2.verizon.com/signin?goto=https://onevzssosoe-sit2-us-west-2.r53np.verizon.com/apis/sec/v1/login'
config.SSO_SIGN_IN = process.env.REACT_APP_SSO_SIGN_IN_URL
config.SSO_SIGN_UP = process.env.REACT_APP_SSO_SIGN_UP_URL

export default config
