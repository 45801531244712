import { createContext, useReducer, useEffect, useContext } from 'react'
import appReducer from './userReducer'
import config from '../../config'
import Cookies from 'js-cookie'

const initialState = {
  userLoading: true,
  user: null,
  message: '',
  notify: false,
}

export const UserContext = createContext(initialState)

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState)

  const clearSignInNotificatiobn = () => {
    setTimeout(() => {
      dispatch({ type: 'CLEAR_SIGN_IN_NOTIFICATION' })
    }, 3000)
  }

  const signInNotification = (message, type) => {
    dispatch({
      type: 'SET_SIGN_IN_NOTIFICATION',
      payload: {
        type: type,
        notify: true,
        message: message,
      },
    })
    clearSignInNotificatiobn()
  }

  const logoutUser = async (action) => {
    await fetch(`${config.PORTAL_URL}/v1/logout`, {
      credentials: 'include',
      mode: 'no-cors',
    })
    dispatch({ type: 'LOGOUT_USER' })

    if (action === 'logout') {
      signInNotification('You are now logged out of your account', 'info');
      window.location.href = "/apis/ns"
    } else {
      signInNotification(
        'Your Session has expired. You have been logged out',
        'warning'
      )
    }
  }

  //Make global cookie method for user token
  const myHostCookie = () =>{
    var hostCookie    = '';
    let hostSplit     = document.location.host.toString().split(".");
    let hostName      = hostSplit[0]; //Get the hostname
    switch(hostName){
        case 'developers-np':
          hostCookie = "userData-sit1|onessoid_sit1";
        break;
        case 'developers-np-test':
          hostCookie = "userData-sit2|onessoid_sit2";
        break;
        case 'developers-np-west':
          hostCookie = "userData-sit2|onessoid_sit2";
        break;
        default:
          //production or local
          hostCookie = "userData|onessoid";
        break;
    }    
    return hostCookie;
  }  

  const getCurrentUser = async () => {
    dispatch({ type: 'GET_CURRENT_USER_BEGIN' })
    try {
          let loggedIn = Cookies.get('loggedIn');
        if(loggedIn){
              let hostCookie = myHostCookie();
              let hostCookEx = hostCookie.toString().split("|");
              console.log("@@Get cookie...", hostCookEx);
              if(Cookies.get(hostCookEx[0]) != null){
                  let getUserData = JSON.parse(Cookies.get(hostCookEx[0]));
                  dispatch({
                    type: 'GET_CURRENT_USER_SUCCESS',
                    payload: getUserData,
                  })
              }
        }        
    } catch (error) {
      dispatch({
        type: 'GET_CURRENT_USER_ERROR',
        payload: {
          error: error.message,
        },
      })
    }
  }

  useEffect(() => {
    let loggedIn = Cookies.get('loggedIn')
    if (loggedIn) {
      getCurrentUser()
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        ...state,        
        logoutUser,
        myHostCookie,
        signInNotification,
        clearSignInNotificatiobn,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

const useUserContext = () => useContext(UserContext)

export { initialState, useUserContext, UserProvider }
