import { initialState } from './userContext'

export default function userReducer(state, { type, payload }) {
  switch (type) {
    case 'GET_CURRENT_USER_BEGIN':
      return { ...state, userLoading: true }
    case 'GET_CURRENT_USER_SUCCESS':
      return {
        ...state,
        userLoading: false,
        user: payload,
      }

    case 'GET_CURRENT_USER_ERROR': {
      return {
        ...state,
        user: null,
        userLoading: false,
        error: payload.error,
        notify: payload.notify,
        type: payload.type,
        message: payload.message,
      }
    }

    case 'LOGOUT_USER':
      return {
        ...initialState,
      }

    case 'SET_SIGN_IN_NOTIFICATION':
      return {
        ...state,
        notify: payload.notify,
        type: payload.type,
        message: payload.message,
      }

    case 'CLEAR_SIGN_IN_NOTIFICATION':
      return {
        ...state,
        notify: false,
        type: 'warning',
        message: '',
      }

    default:
      throw new Error(`no such action : ${type}`)
  }
}
