import { Fragment } from 'react'
import { useUserContext } from '../context/user/userContext'
import { Navigate } from 'react-router-dom'
import { Loader } from '@vds/loaders'
import Cookies from 'js-cookie'

const ProtectedRoute = ({ children }) => {
  //const { user userLoading } = useUserContext()
  const { userLoading } = useUserContext()

  let loggedIn = Cookies.get('loggedIn')
  if (!loggedIn) {
    return <Navigate to="/" /> 
  }

  if (userLoading) { 
    // return (
    //   <Fragment>
    //     <Loader active={true} fullscreen={true} surface="light" />
    //   </Fragment>
    // )
  }

  // if (!user) {
  //   return <Navigate to="/" />
  // }
  return children
}

export default ProtectedRoute
